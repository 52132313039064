<template>
  <div class="my-course-detail">
    <div class="kcxx-box">
      <course-detail :courseTypeMap="courseTypeMap" :detail="coursesDetail" />
      <module-title text="上课情况" />
      <div class="attend-class-box" v-for="(item, index) in cckqList" :key="'skqkitem' + index">
        <div class="label-text">
          {{ item.text }} <span v-if="item.bqhxqText">({{ item.bqhxqText }})</span>
        </div>
        <div class="value-text" :style="{ color: signInStatusColorMap[item.signInStatus] }" v-if="item.signInStatus === 1">
          {{ item.checkinTime.slice(11, 16) }} {{ signInStatusMap[item.signInStatus] }}
        </div>
        <div class="value-text" :style="{ color: signInStatusColorMap[item.signInStatus] }" v-else>{{ signInStatusMap[item.signInStatus] }}</div>
      </div>
      <div class="tips-box">注：如需补签请联系课程管理员</div>
    </div>
    <div class="footer-button">
      <div class="hangdle-button qj" @click="qjOnClick">请 假</div>
      <div v-show="ljqdBtnShow && skTime.currentCheckin !== 1 && skTime.nextCheckin !== 1" class="hangdle-button ljqd" @click="ljqdOnClick">立即签到</div>
      <div v-show="ljqdBtnShow && (skTime.currentCheckin === 1 || skTime.nextCheckin === 1)" class="hangdle-button yqd disablet-btn">已签到</div>
      <div v-show="!ljqdBtnShow" class="hangdle-button kcqyxscnqd disablet-btn">课程开始前1小时才能签到哦</div>
      <!-- <div class="hangdle-button qxbm" v-if="qxbmBtnShow" @click="qxbmOnClick">取消报名</div> -->
    </div>

    <van-popup v-model="popupShow" round position="bottom" safe-area-inset-bottom>
      <van-picker show-toolbar :columns="cckqColumnList" value-key="text" :default-index="defaultIndex" @cancel="popupShow = false" @confirm="qjOnConfirm" />
    </van-popup>
  </div>
</template>

<script>
import moduleTitle from '@/components/bussiness/moduleTitle.vue';
import courseDetail from '../components/courseDetail.vue';
import { setFitApplyPersonOps, signInStatusMap, signInStatusColorMap } from '../map.js';
import { getCourseTypeUrl, getCourseDetailUrl, getMyCourseSheetSessionKqListUrl, saveMyCourseSheetSessionKqUrl } from '../api.js';
import { mapState } from 'vuex';
import moment from 'moment';

moment.locale('zh-cn');

export default {
  name: 'myCourseDetail',
  components: {
    moduleTitle,
    courseDetail
  },
  data() {
    return {
      qjOptions: [
        { text: '123', a: 1 },
        { text: '456', a: 2 }
      ],
      courseTypeMap: {},
      fitApplyPersonOptions: setFitApplyPersonOps(),
      signInStatusMap,
      signInStatusColorMap,
      coursesDetail: {
        id: void 0,
        coursePictureUrl: '',
        coursePictureUrlList: [],
        courseName: '',
        fitApplyPerson: '',
        fitApplyPersonList: [],
        courseType: undefined,
        price: undefined,
        teacher: '',
        teacherPictureUrl: '',
        teacherDescription: '',
        isAstrictPersons: undefined,
        minPerson: undefined,
        maxPerson: undefined,
        applyUserCount: undefined,
        courseSettingList: [],
        courseOpenStart: '',
        courseOpenEnd: '',
        applyDateStart: '',
        applyDateEnd: '',
        courseAddr: '',
        courseAdmin: '',
        courseAdminMobile: '',
        content: '',
        attendClass: [],
        lastTime: ''
      },
      cckqList: [],
      skTime: {
        currentId: void 0,
        currentScheduleId: void 0,
        currentStartTime: '',
        currentEndTime: '',
        currentCheckin: '',
        nextId: void 0,
        nextScheduleId: void 0,
        nextStartTime: '',
        nextEndTime: '',
        nextCheckin: ''
      },
      qjForm: {
        id: void 0,
        courseId: void 0,
        scheduleId: void 0,
        userId: void 0,
        checkin: 2
      },
      popupShow: false,
      timer: null,
      currentDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      defaultIndex: 0,
      cckqColumnList: []
    };
  },
  computed: {
    ...mapState(['userId']),
    // currentCheckin    nextCheckin
    ljqdBtnShow() {
      if (this.skTime.currentStartTime && this.skTime.currentEndTime) {
        return true;
      } else {
        return this.skTime.nextStartTime && new Date(this.currentDate).getTime() + 1000 * 60 * 60 >= new Date(this.skTime.nextStartTime).getTime();
      }
    },
    qxbmBtnShow() {
      return new Date(this.coursesDetail.applyDateStart) <= new Date() && new Date() < new Date(this.coursesDetail.applyDateEnd);
    }
  },
  created() {
    this.$axios
      .get(getCourseTypeUrl, {})
      .then(res => {
        if (res && res.code === 200) {
          res.data = Array.isArray(res.data) ? res.data : [];
          const courseTypeMap = {};
          res.data.forEach(item => {
            courseTypeMap[item.id] = item.courseType;
          });
          this.courseTypeMap = courseTypeMap;
        }
        this.getDetail();
      })
      .catch(() => {
        this.getDetail();
      });
    this.getSessionKqList();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    satrtInterval() {
      if (this.timer) clearImmediate(this.timer);
      this.timer = setInterval(() => {
        this.currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
        this.compList();
      }, 1000);
    },
    getDetail() {
      this.$axios
        .get(getCourseDetailUrl, { params: { id: this.$route.query.id, userId: this.userId } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.isApply = [void 0, '', null, NaN].includes(res.data.isApply) ? 0 : res.data.isApply;
            res.data.coursePictureUrlList = res.data.coursePictureUrl.split('|').filter(item => item);
            res.data.fitApplyPersonList = res.data.fitApplyPerson.split(',').filter(item => item);
            Object.assign(this.coursesDetail, res.data);
          }
        })
        .catch(() => {});
    },
    getSessionKqList() {
      this.$axios
        .get(getMyCourseSheetSessionKqListUrl, { params: { courseId: this.$route.query.id, userId: this.userId } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            const gapTimestampList = [];
            res.data.forEach(item => {
              item.text = item.day + ' ' + item.startTime.slice(0, 5);
              item.gapStartTimestamp = Infinity;
              if (item.cancelFlag === 1) {
                item.signInStatus = 5;
              } else {
                const currentTimestamp = new Date(this.currentDate).getTime();
                const startTimestamp = new Date(item.day + ' ' + item.startTime).getTime();
                const endTimestamp = new Date(item.day + ' ' + item.endTime).getTime();
                if (currentTimestamp < startTimestamp) {
                  if (item.checkin === 2 || item.checkin === 1) {
                    item.signInStatus = item.checkin;
                  } else {
                    item.signInStatus = 4;
                  }
                  item.gapStartTimestamp = startTimestamp - currentTimestamp;
                  gapTimestampList.push(item.gapStartTimestamp);
                } else if (startTimestamp <= currentTimestamp && currentTimestamp <= endTimestamp) {
                  if (['', void 0, null, NaN].includes(item.checkin)) {
                    item.signInStatus = 0;
                  } else {
                    item.signInStatus = item.checkin;
                  }
                  item.bqhxqText = '本期';
                  this.skTime.currentId = item.id;
                  this.skTime.currentScheduleId = item.attendanceId;
                  this.skTime.currentStartTime = item.day + ' ' + item.startTime;
                  this.skTime.currentEndTime = item.day + ' ' + item.endTime;
                  this.skTime.currentCheckin = item.checkin;
                } else if (endTimestamp < currentTimestamp) {
                  if (['', void 0, null, NaN].includes(item.checkin)) {
                    item.signInStatus = 0;
                  } else {
                    item.signInStatus = item.checkin;
                  }
                }
              }
            });
            if (gapTimestampList.length > 0) {
              const minTimestamp = Math.min(...gapTimestampList);
              res.data.some((item, index) => {
                if (item.gapStartTimestamp === minTimestamp) {
                  this.defaultIndex = index;
                  item.bqhxqText = '下期';
                  this.skTime.nextId = item.id;
                  this.skTime.nextScheduleId = item.attendanceId;
                  this.skTime.nextStartTime = item.day + ' ' + item.startTime;
                  this.skTime.nextEndTime = item.day + ' ' + item.endTime;
                  this.skTime.nextCheckin = item.checkin;
                  return true;
                }
              });
            }
            this.cckqList = res.data;
            this.satrtInterval();
          }
        })
        .catch(() => {});
    },
    compList() {
      Object.assign(this.skTime, {
        currentId: void 0,
        currentScheduleId: void 0,
        currentStartTime: '',
        currentEndTime: '',
        currentCheckin: '',
        nextId: void 0,
        nextScheduleId: void 0,
        nextStartTime: '',
        nextEndTime: '',
        nextCheckin: ''
      });
      const gapTimestampList = [];
      this.cckqList.forEach(item => {
        item.text = item.day + ' ' + item.startTime.slice(0, 5);
        item.gapStartTimestamp = Infinity;
        if (item.cancelFlag === 1) {
          item.signInStatus = 5;
        } else {
          const currentTimestamp = new Date(this.currentDate).getTime();
          const startTimestamp = new Date(item.day + ' ' + item.startTime).getTime();
          const endTimestamp = new Date(item.day + ' ' + item.endTime).getTime();
          if (currentTimestamp < startTimestamp) {
            if (item.checkin === 2 || item.checkin === 1) {
              item.signInStatus = item.checkin;
            } else {
              item.signInStatus = 4;
            }
            item.gapStartTimestamp = startTimestamp - currentTimestamp;
            gapTimestampList.push(item.gapStartTimestamp);
          } else if (startTimestamp <= currentTimestamp && currentTimestamp <= endTimestamp) {
            if (['', void 0, null, NaN].includes(item.checkin)) {
              item.signInStatus = 0;
            } else {
              item.signInStatus = item.checkin;
            }
            item.bqhxqText = '本期';
            this.skTime.currentId = item.id;
            this.skTime.currentScheduleId = item.attendanceId;
            this.skTime.currentStartTime = item.day + ' ' + item.startTime;
            this.skTime.currentEndTime = item.day + ' ' + item.endTime;
            this.skTime.currentCheckin = item.checkin;
          } else if (endTimestamp < currentTimestamp) {
            if (['', void 0, null, NaN].includes(item.checkin)) {
              item.signInStatus = 0;
            } else {
              item.signInStatus = item.checkin;
            }
          }
        }
      });
      if (gapTimestampList.length > 0) {
        const minTimestamp = Math.min(...gapTimestampList);
        this.cckqList.some(item => {
          if (item.gapStartTimestamp === minTimestamp) {
            item.bqhxqText = '下期';
            this.skTime.nextId = item.id;
            this.skTime.nextScheduleId = item.attendanceId;
            this.skTime.nextStartTime = item.day + ' ' + item.startTime;
            this.skTime.nextEndTime = item.day + ' ' + item.endTime;
            this.skTime.nextCheckin = item.checkin;
            return true;
          }
        });
      }
    },
    qjOnClick() {
      Object.assign(this.qjForm, {
        id: void 0,
        courseId: this.coursesDetail.id,
        scheduleId: void 0,
        userId: this.userId,
        checkin: 2
      });
      this.cckqColumnList = this.$_.cloneDeep(this.cckqList).map(item => ({
        ...item,
        disabled: new Date() >= new Date(item.day + ' ' + item.endTime) || item.checkin === 2
      }));
      this.$nextTick(() => {
        this.popupShow = true;
      });
    },
    ljqdOnClick() {
      const form = {
        id: this.skTime.currentScheduleId || this.skTime.nextScheduleId,
        courseId: this.coursesDetail.id,
        scheduleId: this.skTime.currentId || this.skTime.nextId,
        userId: this.userId,
        checkin: 1
      };
      this.$axios
        .post(saveMyCourseSheetSessionKqUrl, form)
        .then(res => {
          if (res && res.code === 200) {
            this.$toast(res.msg);
            this.getSessionKqList();
          }
        })
        .catch(() => {});
    },
    qxbmOnClick() {},
    qjOnConfirm(value) {
      this.popupShow = false;
      this.qjForm.id = value.attendanceId;
      this.qjForm.scheduleId = value.id;
      this.$axios
        .post(saveMyCourseSheetSessionKqUrl, this.qjForm)
        .then(res => {
          if (res && res.code === 200) {
            this.$toast(res.msg);
            this.getSessionKqList();
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.my-course-detail {
  box-sizing: border-box;
  background-color: #fafafa;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  padding-bottom: 100px;
  .kcxx-box {
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 30px;
  }
}
.attend-class-box {
  margin: 15px 30px 0px 30px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.8);
  border-radius: 16px;
  padding: 26px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  line-height: 26px;
}
.tips-box {
  box-sizing: border-box;
  font-size: 24px;
  color: #e02020;
  line-height: 34px;
  margin: 30px 34px 30px 34px;
}
.footer-button {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 16px 30px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  z-index: 9;
  .hangdle-button {
    width: calc(50% - 20px);
    height: 68px;
    background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    border-radius: 10px;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.qj {
      background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
    }
    &.ljqd {
      margin-left: 40px;
    }
    &.yqd {
      margin-left: 40px;
    }
    &.kcqyxscnqd {
      margin-left: 40px;
      font-size: 22px;
    }
    &.qxbm {
      width: 80%;
      background: linear-gradient(180deg, #ed1d1d 0%, #ef6565 100%);
    }
    &.disablet-btn {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      color: #a8a8a8;
    }
  }
}

.module-title {
  margin: 30px 30px 0px 30px;
}
</style>
